import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");
 var domainName = ReactSession.get('domain')
 console.log(domainName);
const config = () => ({
    apiUrl: 'https://'+domainName+'/admin/api/',
    apiKey: 'your-api-key',
});

export const updateDomain = (newDomain) => {
    domainName = newDomain;
  };

export default config;


// pos.foodship.net
//43933990
//50434301
//79357441

//posdemo.foodship.com.au
//64301688


